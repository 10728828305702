<template>
  <div v-if="isMobile" class="lesson_path_container" style="margin-top: 23px; padding-top: 0px;">
    <span style="margin-bottom: 0px; margin-top: 0px; padding-top: 0px;" class="lesson_path_subtitle">{{ getCurrentModule.title }}</span>
    <span style="margin-top: 7px;" class="lesson_path_title">
        {{ getCurrentLesson.title }}
    </span>
  </div>
  <div v-else class="lesson_path_container">
    <span class="lesson_path_title">{{ getCurrentModule.title }}</span>
    <span class="lesson_path_subtitle">
      <span style="cursor: pointer;" @click="$router.push('/home')">Home</span>
      <svg class="icon-path" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L5 5L1 1" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span>
        {{ getCurrentLesson.title }}
      </span>
    </span>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['isMobile'],
  computed: {
    ...mapGetters(['getCurrentLesson', 'getCurrentModule']),
  }
}
</script>

<style>
  .lesson_path_container {
    display: flex;
    flex-direction: column;
  }
  .lesson_path_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    color: var(--fontcolor-v2)
  }
  .lesson_path_subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 37px;
    color: #9A9A9A;
  }
  .icon-path {
    margin-left: 15px;
    margin-right: 15px;
  }
</style>